import styled from "@emotion/styled"
import { mq } from "../../util/theme"

export const ToggleWrap = styled.section`
  background: ${props => props.theme.colors.blue};
  padding: 0.75rem 0;
  display:flex;
  margin: auto;
  padding: .25rem 1.5rem;
  position: relative;
  justify-content: space-around;
  align-items: center;
  min-height: 42px;
  &.rtl{
    .react-toggle{
      &--checked{
        .react-toggle-thumb{
          left: 1px;
          right: auto;
        }
      }
      &-thumb{
        right: 1px;
        left: auto;
      }
    }
  }
  @media print{
    display: none;
  }
`

export const ToggleHolder = styled.section`
  ${mq[1]} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const ToggleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 160px;
  width: 100%;
  margin: auto;
  ${mq[1]} {
    max-width: 700px;
    margin: 0;
  }
  div{
    margin: 0 .25rem;
  }
`

export const ToggleLabel = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 500;
  letter-spacing: ${props => props.theme.leading.tiny};
  margin: 0;
  display: none;
  ${mq[1]} {
    display: block;
  }
  &.is-mob {
    display: block;
    ${mq[1]} {
      display: none;
    }
  }
`

export const MobHeader = styled.header`
  background: ${props => props.theme.colors.copy};
  padding: 1.5rem 0;
  top: 0;
  z-index: 1;
  &.is-sticky {
    position: sticky;
  }
  ${mq[2]} {
    display: none;
  }
`

export const MobWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mq[2]} {
    display: none;
  }
`

export const MobLogo = styled.img`
  display: block;
  max-width: 140px;
  width: 100%;
  ${mq[2]} {
    display: none;
  }
`

export const HeaderWrap = styled.header`
  background: url() no-repeat center/cover;
`

export const HeaderShim = styled.div`
  background: linear-gradient(
    90deg,
    rgba(33, 51, 58, 0.45),
    rgba(33, 51, 58, 0)
  );
  min-height: 160px;
  padding: 2rem 0;
  ${mq[2]} {
    min-height: 300px;
  }
`

export const HeaderLogo = styled.img`
  display: none;
  max-width: 215px;
  width: 100%;
  margin: 0 0 2rem -2rem;
  ${mq[2]} {
    display: block;
  }

  @media print{
    display: block;
    margin: 0 0 2rem 0;
  }
`

export const HeaderCta = styled.a`
  display: none;
  max-width: 210px;
  width: 100%;
  position: absolute;
  top: -2rem;
  right: 0;
  background: ${props => props.theme.colors.gold};
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.copy};
  font-weight: ${props => props.theme.weights.bold};
  text-decoration: none;
  text-align: center;
  padding: 1rem 0;
  border-radius: 0 0 5px 5px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    background: ${props => props.theme.colors.yellow};
  }
  ${mq[1]} {
    display: block;
  }
`

export const HeaderTitle = styled.h1`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.title};
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.weights.reg};
  margin: 0;

  @media print{
    color: ${props => props.theme.colors.blue};
    font-size: 36px;
    line-height: 1.12;
  }
`

export const HeaderSub = styled.h3`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.h3};
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.weights.reg};
  margin: 0;

  @media print{
    color: ${props => props.theme.colors.blue};
  }
`

export const ModalBody = styled.section`
  background: ${props => props.theme.colors.white};
  position: relative;
  max-width: 615px;
  width: 100%;
`

export const ModalHeader = styled.h3`
  background: ${props => props.theme.colors.copy};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.h3};
  font-weight: ${props => props.theme.weights.reg};
  text-align: center;
  padding: 2rem;
  margin: 0;
  ${mq[1]} {
    padding: 2rem 3rem;
  }
`

export const ModalCopy = styled.p`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.h3};
  font-weight: ${props => props.theme.weights.bold};
  text-align: center;
  margin: 0;
  padding: 3rem 0 2rem;
`

export const ModalBtn = styled.a`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.robins};
  font-size: ${props => props.theme.fontSizes.h3};
  font-weight: ${props => props.theme.weights.extra};
  text-transform: uppercase;
  text-align: center;
  max-width: 175px;
  text-decoration: none;
  padding: 1rem 0;
  width: 100%;
  margin: 0;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
  }
`

export const ModalBtns = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 365px;
  width: 100%;
  margin: 0 auto 3rem;
`

export const ModalClose = styled.button`
  background: transparent;
  border: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  z-index: 99999;
  &:focus {
    outline: 0;
  }
`

export const DropdownWrap = styled.div`
  display:inline;
  min-width: 150px;

  .Dropdown{
    &-control{
      background-color: #0C568A;
      border: 0;
      color: white;
    }
    &-menu{
      background-color: #0C568A;
      border: 0;
      max-height: 700px;
    }
    &-option{
      color:white;
      &:hover{
        background-color: #0C568A;
      }
      &.is-selected{
        background-color: #0C568A;
      }
    }
  }
  
  
`;
